.timeline-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  margin-top: 20px;
  width: 100%;
}

.bolder {
  font-weight: 500;
}

.arrow-line {
  width: 100%;
  height: 4px;
  background-color: #0055a5d5;
  border-radius: 50px;
}

.timeline-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 0rem 1rem;
  font-weight: bold;
  font-size: 3rem;
  border-radius: 50px;
  border: 1px solid #0055a5;
}
.arrow-container {
  width: 15%;
  display: flex;
  align-items: center;
}
.arrow-svg {
  width: 3rem;
  fill: #0055a5d5;
}
.timeline-text {
  text-align: center;
  font-size: 1.8rem;
  font-weight: 700;
}

.prize-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  img {
    width: 100%;
    height: 100%;
    padding: 0 50px;
    margin: 10px;
  }
}

.header-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
  padding: 2rem;
  .goal-content {
    width: 90%;
    margin: auto;
  }
  h1 {
    color: black;
    font-weight: 500;
    margin: 0;
    text-align: start;
  }
  p {
    color: black;
    padding-top: 2rem;
    font-size: 1.6rem;
    font-weight: 200;
  }
}

.button-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  padding-bottom: 2.5rem;
  .apply-button {
    padding: 0.8rem 8rem;
    background-color: #fbe67b;
    border: none;
    border-radius: 5px;
    font-size: 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
}

.apply-button:hover {
  background-color: #f0d456;
}



@media only screen and (min-width: 290px) and (max-width: 600px) {
  .timeline-container {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 0 0px;
    width: 100%;
  }
  .arrow-svg {
    width: 1rem;
  }
  .arrow-line {
    width: 100%;
    height: 2px;
    background-color: rgba(0, 85, 165, 0.8352941176);
    border-radius: 50px;
  }

  .timeline-text {
    text-align: center;
    font-size: 0.5rem;
    font-weight: 700;
  }

  .header-container .goal-content {
    width: 100%;
    margin: auto;
  }

  .timeline-box {
    width: max-content;
    font-size: 10px;
    height: auto;
    margin: 6px 0;
    padding: 2px 4px;
  }

  .arrow-container {
    width: 10%;
    display: flex;
    align-items: center;
  }

  .prize-image {
    margin-top: 10px;
    img {
      padding: 0;
      margin: 10px 0;
      width: 90%;
    }
  }

  .header-container {
    margin-left: 0;
    align-items: center;
    padding-bottom: 0.5rem;

    h1 {
      font-size: 24px;
      text-align: center;
    }
    p {
      font-size: 14px;
      text-align: center;
      padding-top: 1rem;
    }
  }

  .button-container {
    margin: 10px 0;
    padding-bottom: 2rem;
    .apply-button {
      padding: 0.8rem 6rem;
      font-size: 1rem;
    }
  }

  
}

@media only screen and (min-width: 600px) and (max-width: 1200px) {
  .timeline-container {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 0 0px;
    width: 100%;
  }
  .arrow-svg {
    width: 1rem;
  }
  .arrow-line {
    width: 100%;
    height: 2px;
    background-color: rgba(0, 85, 165, 0.8352941176);
    border-radius: 50px;
  }

  .timeline-text {
    text-align: center;
    font-size: 1rem;
    font-weight: 700;
  }
  .timeline-box {
    font-size: 2rem;
  }
}

.contactUs {
  display: flex;
  flex-direction: column;
  align-items: center; 
padding: 20px;
font-size: 1.5rem;
}

.timeline-container {
  width: 100%;
  background-color: #f3f4f6;
  padding: 40px 0;
  display: flex;
  justify-content: center;
}

.timeline-card {
  width: 50%;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
}

.timeline-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.timeline-info {
  margin-top: 20px;
  font-size: 1.2rem;
}

.qr-image {
  max-width: 200px; 
  height: auto;
  margin-top: 10px;
}


@media (max-width: 768px) {
  .timeline-card {
    width: 90%;
    padding: 10px;
  }

  .timeline-title {
    font-size: 1.4rem;
  }

  .timeline-info {
    font-size: 1.2rem;
  }

  .qr-image {
    max-width: 150px;
  }
  .contactUs {
    align-items: center; 
    font-size: 0.8rem;
  }
}


@media (max-width: 1024px) {
  .timeline-card {
    width: 80%;
    padding: 15px;
  }

  .timeline-title {
    font-size: 1.6rem;
  }

  .timeline-info {
    font-size: 1.3rem;
  }

  .qr-image {
    max-width: 180px;
  }
  .contactUs {
    align-items: center; 
    font-size: 0.8rem;
  }
}


@media (max-width: 768px) {
  .timeline-card {
    width: 90%;
    padding: 10px;
  }

  .timeline-title {
    font-size: 1.4rem;
  }

  .timeline-info {
    font-size: 1.2rem;
  }

  .qr-image {
    max-width: 150px;
  }

  .contactUs {
    align-items: center; 
    font-size: 0.8rem;
  }
}
