@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import "../colorvariables.scss";

.contactus_main_component_div{
  height: 100%;
}
.error-message{
  color: red;
}
.contact {
  width: 100%;
  height: 100%;
  background-image: url(../assets/AboutBg.png);
  position: relative;
  .contact-container {
    height: 100%;
    color: $white-color;
    display: flex;
    .contact-left {
      width: 50%;
      display: inline-block;
      background-color: $primary-color;
      height: 100%;
      vertical-align: top;
      h1.contact-header {
        font-family: "Montserrat", sans-serif;
      }
      .contact-form {
        padding: 5rem;
        padding-top: 10rem;
        .contact-description {
          width: 80%;
          font-size: 22px;
        }
        .form-details {
          padding-top: 2rem;
          .contact-label {
            display: block;
            padding-bottom: 10px;
            font-size: 18px;
            font-weight: bold;
          }
          .contact-textfield {
            border-radius: 25px;
            width: 70%;
            padding: 12px;
            border: 0px;
            margin-bottom: 2rem;
            background: #eeeeee 0% 0% no-repeat padding-box;
            box-shadow: inset 0px 3px 6px #00000029;

            .contact-textfield > input {
              border: none !important;
              background-color: none !important;
              box-shadow: none !important;
            }
          }
          .submit {
            padding-top: 2rem;
            .contact-submit {
              width: 70%;
              padding: 10px;
              border-radius: 30px;
              border: 0px;
              background-color: $yellow-color;
            }
          }
        }
      }
    }
    .contact-right {
      width: 50%;
      min-height: 100%;
      display: flex;
      align-items: center;

      .map-container {
        height: 60vh;
        width: 100%;
        position: relative;

        iframe.pen-india-map {
          width: 100%;
          height: 100%;
          position: absolute;
          width: 100%;
          top: 50%;
          transform: translateY(-50%);
          left: -5rem;
        border-radius: 30px;


        }
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 1600px) {
  .contact {
    // height: 75vh;
    height: 100%;
    .contact-container {
      .contact-left {
        padding-bottom: 4rem;
        .contact-form {
          padding: 5rem;
          padding-left: 2rem;
          .contact-description {
            width: 100%;
            font-size: 16px;
          }
          .form-details {
            padding-top: 2rem;
            .contact-label {
              display: block;
              padding-bottom: 8px;
              font-size: 16px;
              font-weight: bold;
            }
            .contact-textfield {
              margin-bottom: 1rem;
            }
            .submit {
              padding-top: 1rem;
              .contact-submit {
                width: 70%;
                padding: 10px;
                border-radius: 30px;
                border: 0px;
              }
            }
          }
        }
      }

      // .contact-right {
      //   width: 50%;
      //   display: inline-block;
      //   // height: 100%;
      //   height: 650px;
      //   position: relative;
      //   .map-container {
      //     position: absolute;
      //     width: 100%;
      //     top: 50%;
      //     transform: translateY(-50%);
      //     left: -4rem;
      //     iframe.pen-india-map {
      //       width: 100%;
      //       // height: 45vh;
      //       height: 500px;
      //       border-radius: 30px;
      //     }
      //   }
      // }
    }
  }
}

@media only screen and (min-width: 290px) and (max-width: 600px) {
  .contact {
    width: 100%;
    height: unset;
    background-image: url(../assets/AboutBg.png);
    position: relative;
    .contact-container {
      height: 100%;
      flex-direction: column;
      .contact-left {
        width: 100%;
        display: block;
        .contact-form {
          padding: 1rem;
          padding-top: 5rem;
          padding-bottom: 10rem;
          .contact-header {
            width: calc(100% - 30px);
            padding-left: 10px;
          }
          .contact-description {
            width: calc(100% - 30px);
            margin: auto;
          }
          .form-details {
            padding-top: 2rem;
            .contact-label {
              display: block;
              padding-bottom: 10px;
              font-size: 18px;
              font-weight: bold;
            }
            .contact-textfield {
              border-radius: 25px;
              width: 95%;
              padding: 12px;
              border: 0px;
            }
            .submit {
              padding-top: 2rem;
              .contact-submit {
                width: 95%;
                padding: 10px;
                border-radius: 30px;
                border: 0px;
              }
            }
          }
        }
      }
      .contact-right {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 450px;
        position: relative;

        .map-container {
          width: 94%;
          transform: unset;
       
          iframe.pen-india-map {
          position: absolute;

            width: 100%;
            height: 56vh;
            border-radius: 30px;
            left: 50%;
            transform: translateX(-50%);
            top: -6rem;
          }
        }
      }
    }
  }
}
