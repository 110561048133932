@import "../colorvariables.scss";





.nav_option_div {
  background-color: $white-color;
  background: url("../assets/PEN.svg") no-repeat !important;
  height: 10vh;
  width: 100%;
  color: #0c0c0c;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;

  // Add burger menu styles here
  .burger_menu {
    display: none;
    cursor: pointer;
    margin-left: auto;
    padding-right: 1rem;
    z-index: 101;
    
    @media only screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 0;
    }
    
    div {
      width: 25px;
      height: 3px;
      background-color: #0c0c0c;
      margin: 3px 0;
      transition: all 0.3s ease;
    }
    
    &.open {
      div:nth-child(1) {
        transform: rotate(-45deg) translate(-5px, 6px);
      }
      div:nth-child(2) {
        opacity: 0;
      }
      div:nth-child(3) {
        transform: rotate(45deg) translate(-5px, -6px);
      }
    }
  }

  // Add mobile menu styles here
  .mobile_menu {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100vh;
    background-color: white;
    z-index: 100;
    transition: left 0.3s ease-in-out;
    display: none;
    
    @media only screen and (max-width: 600px) {
      display: block;
    }
    
    &.open {
      left: 0;
    }
    
    .mobile_menu_list {
      padding: 80px 2rem 2rem;
      list-style: none;
      
      .navbar_list_comp {
        margin: 2rem 0;
        
        .navbar_link_button {
          font-size: 1.2rem;
          display: block;
          width: 100%;
          text-align: left;
          
          &::after {
            bottom: -5px;
          }
        }
      }
    }
  }

  .pen_logo_component {
    width: 40%;
    height: 100%;

    .pen_logo {
      width: 25%;
      object-fit: cover;
    }
  }

  .nav_bar_main_list_component {
    width: 55%;
    height: 100%;
    list-style: none;
    margin: 0;
    display: flex;
    justify-content: space-between;
    padding-right: 2rem;
    
    .navbar_list_comp {
      margin: 0 1rem;
      list-style: none;
    }
  
    .navbar_link_button {
      position: relative;
      width: max-content;
      background-color: transparent;
      border: none;
      color: black;
      cursor: pointer;
      font-size: 1rem;
      text-transform: uppercase;
      z-index: 99;
      text-decoration: none !important;
  
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -10px;
        left: 0;
        background-color: #0055a5;
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 0.3s ease-in-out;
      }
  
      &.active {
        color: $primary-color;
        
        &::after {
          transform: scaleX(1);
        }
      }
    }
  }
  .menubar_div {
    display: none;
  }
}

@media only screen and (min-width: 290px) and (max-width: 600px) {
  .nav_option_div {
    background-color: white;
    height: 8% !important;
    width: 100%;
    color: #0c0c0c;
    display: flex;
    justify-content: start;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
    .pen_logo_component {
      height: 100%;
      .pen_logo {
        width: 100%;
        object-fit: cover;
      }
    }
    
    .nav_bar_main_list_component {
      display: none !important;
      width: 80%;
      height: 100%;
      list-style: none;
      margin: 0;
      .navbar_list_comp {
        margin: unset !important;
        list-style: none;
        margin-right: 0.1rem !important;
      }
      .navbar_link_button {
        background-color: transparent;
        border: none;
        color: black;
        cursor: pointer;
        font-size: 0.7rem;
        text-transform: uppercase;
        margin: unset !important;
      }
      .navbar_link_button:hover {
        color: #0055a5;
      }
    }
    .menubar_div {
      display: block !important;
    }
  }
}

h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

p {
  font-size: 1.2rem;
}

@media only screen and (min-width: 290px) and (max-width: 600px) {
  .nav_option_div {
    background-color: $white-color;
    height: fit-content;
    width: 100%;
    color: #0c0c0c;
    display: flex;
    justify-content: start;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;

    .pen_logo_component {
      width: 30%;
      height: 100%;

      .pen_logo {
        width: 100%;
        object-fit: cover;
      }
    }

    .nav_bar_main_list_component {
      display: none !important;
      width: 80%;
      height: 100%;
      list-style: none;
      margin: 0;

      .navbar_list_comp {
        margin: unset !important;
        list-style: none;
        margin-right: 0.1rem !important;
      }

      .navbar_link_button {
        background-color: transparent;
        border: none;
        color: black;
        cursor: pointer;
        font-size: 0.7rem;
        text-transform: uppercase;
        margin: unset !important;
      }

      .navbar_link_button:hover {
        color: $primary-color;
      }
    }
    .menubar_div {
      display: block !important;
    }
  }
}
@media screen and (min-width: 290px) and (max-width: 600px) and (orientation: landscape) {
  .nav_option_div {
    background-color: $white-color;
    background: url("../assets/PEN.svg") no-repeat !important;
    height: 10vh;
    width: 100%;
    color: #0c0c0c;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;

    .pen_logo_component {
      width: 40%;
      height: 100%;
      margin-right: 50%;

      .pen_logo {
        width: 25%;
        object-fit: cover;
      }
    }

    .nav_bar_main_list_component {
      width: 60%;
      height: 100%;
      list-style: none;
      margin: 0;
      .navbar_list_comp {
        margin: 0 1rem;
        list-style: none;
        width: 28%;
      }

      .navbar_link_button {
        background-color: transparent;
        border: none;
        color: black;
        cursor: pointer;
        font-size: 1rem;
        text-transform: uppercase;
        margin: 0 1rem;
        width: max-content;
        z-index: 99;
      }

      .navbar_link_button {
        position: relative;
        width: max-content;
        &:hover {
          color: $primary-color;
          text-decoration: underline;
          text-decoration-color: #0055a5;
          text-underline-offset: 10px;

          // &::before {
          //   content: "";
          //   width: inherit;
          //   height: 30px;
          //   position: absolute;
          //   background-color:#0065b361;
          //   right: 0;
          //   // z-index: 0;
          // }
        }
      }
    }
    .menubar_div {
      display: none;
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .nav_option_div {
    justify-content: flex-start;
  }
  .nav_option_div .pen_logo_component {
    width: 20%;
    height: 100%;
  }
  .nav_option_div .pen_logo_component .pen_logo {
    width: 60%;
    object-fit: cover;
  }
  .nav_option_div .nav_bar_main_list_component {
    width: 80%;
    height: 100%;
    list-style: none;
    margin: 0;
    padding-left: 0;
  }
  .nav_option_div .nav_bar_main_list_component .navbar_list_comp {
    margin: 0 0rem;
  }
  .nav_option_div .nav_bar_main_list_component .navbar_link_button {
    margin: 0 0rem;
  }
}
