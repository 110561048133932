.scholar-awards {
  background-image: url("../assets/vijkarthi_a_closeup_shot_of_a_south_indian_middle_aged_male_g_6360a2e5-e0c9-4bb7-a966-7615e883d3c8_1 1.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: end;
  padding: 2rem;
  color: white;
  flex-direction: column;
  box-shadow: inset 0px 0px 36px 6px #094a8b;
}

.scholar-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.scholar-content p {
  margin: 0;
  padding-left: 0;
  font-size: 1.5rem;
  font-weight: 200;
}

.image-section {
  display: flex;
  flex-direction: row;
  gap: 20%;
  margin-top: 10px;
  padding: 10px;
  width: 40%;
  padding-left: 0;
}

.image-section img {
  width: 30%;
  object-fit: contain;
}
.youth-image {
  width: 40% !important;
}

.bold-text {
  font-weight: bold;
}
.scholar-awards-logo-container {
  width: 35%;
}
.scholars-logo {
  width: 80%;
  margin-bottom: 2rem;
}

@media only screen and (min-width: 290px) and (max-width: 600px) {
  .scholar-awards {
    height: auto;
    padding: 15px;
    justify-content: center;
    align-items: center;
  }

  .scholar-content {
    align-items: center;
  }

  .scholar-content p {
    padding: 10px;
    text-align: center;
    font-size: 0.8rem;
    padding-bottom: 2rem;
  }

  .image-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    width: 100%;
  }

  .image-section img {
    width: 30%;
    max-width: 100px;
    height: auto;
  }
  .scholar-container {
    padding-top: 4rem;
  }
  .scholar-awards-logo-container {
    width: 45%;
  }

}
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .image-section {
    gap: 9%;
    width: 85%;
  }
}
