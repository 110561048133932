@import url("https://fonts.googleapis.com/css2?family=Raleway&family=Source+Sans+Pro&display=swap");
@import "../colorvariables.scss";

.MuiInputBase-root {
  border: none !important;
  background-color: none !important;
}

.top_carousel_main_div {
  // margin-top: 10vh;
  // height: 90vh;
  height: 100vh !important;
  width: 100%;

  .landing_page_carousel_comp_div {
    height: 100%;
    width: 100%;
    position: relative;

    .carousel_component {
      height: 100vh;
      width: 100%;

      .image_carousel_main_div {
        height: 100%;
        width: 100%;

        &.tab {
          display: none;
        }
        &.mobile {
          display: none;
        }

        .carousel-slider {
          height: 100%;
          width: 100%;

          .carousel_image {
            height: 100%;
            width: 100%;
            object-fit: fill;
          }
        }
      }
    }

    .carousel_content_div {
      position: absolute;
      width: 40%;
      height: 50%;
      left: 10%;

      .carousel_title_div {
        height: 20%;
        font-size: 2rem;
        font-weight: 500;
        color: $white-color;
        opacity: 0.8;
      }
      .carousel_subtitle_div {
        height: 20%;
        font-size: 1rem;
        font-weight: 400;
        color: $white-color;
        opacity: 0.8;
      }
      .carousel_txt_div {
        height: 30%;
        font-size: 1rem;
        font-weight: 400;
        color: $white-color;
        opacity: 0.8;
      }

      .carousel_knowmore_div {
        height: 20%;

        .knowmore_button_component_div {
          width: 35%;
          height: 3rem;
          position: relative;
          display: flex;
        }

        .knowmore_button {
          height: 100%;
          width: 100%;
          border: none;
          background-color: $secondary-color;
          font-size: 1rem;
          border-radius: 1.5rem;
          font-weight: 600;
          color: $white-color;
        }

        .knowmore_arrow {
          position: absolute;
          height: 100%;
          width: 2.7rem;
          background-color: $white-color;
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          border-radius: 50%;
          right: 0;
          top: 0;
          cursor: pointer;
          box-shadow: 0 10px 10px rgb(0 0 0 / 0.2);
        }
      }
    }

    .carousel_social_links {
      position: absolute;
      right: 2%;
      height: 20%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      z-index: 9;

      .social_icons {
        cursor: pointer;

        svg {
          color: $white-color;
          font-size: 1.2rem;
        }
      }
    }
  }
}
.carousel_image {
  height: 100%;
  width: 100%;
  object-fit: fill;
}


.main {
  text-align: center;
  padding-top: 28px;
}

// .marquee {
//   padding-top: 40px;
//   background-color: #E6E6FA;
  
// }

.Apply {
  font-size: 24px;
  color: black;
  padding-bottom: 18px;
 
}

.apply-link {
  text-decoration: none; 
  color: #4169E1;        
  font-weight: 400;   
  cursor: pointer;      
}

.apply-link:hover {
  text-decoration: underline; 
}

// .marquee .gap {
//   display: inline-block; 
//   width: 50px;           
// }






@media screen and (min-width: 1350px) {
  .top_carousel_main_div {
    margin-top: 10vh;
    height: 90vh;
    width: 100%;

    .landing_page_carousel_comp_div {
      height: 100%;
      width: 100%;
      position: relative;

      .carousel_component {
        height: 100%;
        width: 100%;

        .image_carousel_main_div {
          height: 100%;
          width: 100%;
          &.tab {
            display: none;
          }
          &.mobile {
            display: none;
          }
          .carousel-slider {
            height: 100%;
            width: 100%;
          }
        }
      }

      .carousel_content_div {
        position: absolute;
        width: 40%;
        height: 50%;
        left: 7%;

        .carousel_title_div {
          height: 20%;
          font-size: 3rem;
          font-weight: 500;
          opacity: 0.8;
        }
        .carousel_subtitle_div {
          height: 20%;
          font-size: 1.5rem;
          font-weight: 400;
          opacity: 0.8;
        }
        .carousel_txt_div {
          height: 30%;
          font-size: 1.3rem;
          font-weight: 400;
          opacity: 0.8;
          font-family: "Raleway", sans-serif;
        }

        .carousel_knowmore_div {
          height: 20%;

          .knowmore_button_component_div {
            width: 35%;
            height: 3.5rem;
            position: relative;
            display: flex;
          }

          .knowmore_button {
            height: 100%;
            width: 100%;
            border: none;
            background-color: #0055a5;
            font-size: 1rem;
            border-radius: 1.5rem;
            font-weight: 600;
            color: white;
            box-shadow: inset 3px 3px 6px rgba(0, 0, 0, 0.16);
          }

          .knowmore_arrow {
            position: absolute;
            height: 100%;
            width: 3.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            border-radius: 50%;
            right: 0;
            top: 0;
          }
        }
      }

      .carousel_social_links {
        position: absolute;
        right: 2%;
        height: 20%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        z-index: 9;

        .social_icons {
          cursor: pointer;

          svg {
            font-size: 2rem;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 290px) and (max-width: 600px) {
  .top_carousel_main_div {
    position: relative;
    height: calc(100vh - 60px);
    .landing_page_carousel_comp_div {
      // height: calc(100vh - 60px) !important;
      .carousel_component {
        height: unset;
      }
    }
    .carousel_content_div {
      position: absolute;
      width: 40%;
      height: 50%;
      left: 10%;
      top: 55%;
      .carousel_title_div {
        height: 5% !important;
        font-size: 2rem;
        font-weight: 500;
        color: white;
        opacity: 0.8;
        padding-left: 2rem;
        padding-right: 2rem;
      }
      .carousel_subtitle_div {
        height: 10% !important;
        font-size: 1rem;
        font-weight: 400;
        color: white;
        opacity: 0.8;
        padding-left: 2rem;
        padding-right: 2rem;
        display: flex;
        align-items: center;
      }
      .carousel_txt_div {
        height: 10% !important;
        font-size: 1rem;
        font-weight: 400;
        color: white;
        opacity: 0.8;
        padding-left: 2rem;
        padding-right: 2rem;
      }
      .carousel_knowmore_div {
        display: none !important;
      }
    }
    .carousel_social_links {
      // position: absolute;
      // right: unset !important;
      // height: 20%;
      // width: 40%;
      // display: flex;
      // flex-direction: row !important;
      // justify-content: space-around;
      // bottom: 0%;
      position: absolute;
      right: 0 !important;
      height: 20%;
      display: flex;
      flex-direction: column !important;
      justify-content: end !important;
      width: max-content;
      top: 50%;
      z-index: 9;
      .social_icons {
        cursor: pointer;
        svg {
          color: white;
          font-size: 1.4rem !important;
        }
      }
    }
  }
  .top_carousel_main_div .landing_page_carousel_comp_div .carousel_content_div {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
  }
  .top_carousel_main_div
    .landing_page_carousel_comp_div
    .carousel_content_div
    .carousel_knowmore_div
    .knowmore_button_component_div {
    display: none;
  }
  // .top_carousel_main_div
  //   .landing_page_carousel_comp_div
  //   .carousel_component
  //   .image_carousel_main_div
  //   .carousel-slider
  //   .carousel_image {
  //   width: 100vh;
  //   height: 100vh;
  // }
  .image_carousel_main_div {
    height: 100%;
    width: 100%;
    &.tab {
      display: none;
    }
    &.desktop {
      display: none;
    }
    &.mobile {
      display: block !important;
      height: calc(100vh - 60px) !important;
    }
  }
  .swiper-slide.carousel_slide_div.swiper-slide-active {
    height: 100vh !important;
  }
  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 0vh;
    position: relative;
    transition-property: transform;
    display: block;
    .img.carousel-image {
      width: 100%;
      height: auto;
      max-height: 100%;
      object-fit: contain;
    }
  }
} // Default Css.carousel-status { display: none; } .main_footer_div { // height: 50vh; box-shadow: 0px -29px 29px #00000029; }

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .top_carousel_main_div {
    height: 100vh;
    width: 100%;

    .landing_page_carousel_comp_div {
      .carousel_component {
        .image_carousel_main_div {
          &.tab {
            display: block !important;
          }
          &.mobile {
            display: none;
          }
          &.desktop {
            display: none;
          }

          .carousel-slider {
            .carousel_image {
              height: 100vh;
              width: 100%;
            }
          }
        }
      }

      .carousel_content_div {
        position: absolute;
        width: 90%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .carousel_title_div {
          // height: 10%;
          height: 25%;
        }
        .carousel_subtitle_div {
          // height: 10%;
          height: 25%;
        }
        .carousel_txt_div {
          // height: 10%;
          height: 25%;
        }

        .carousel_knowmore_div {
          height: 20%;

          .knowmore_button_component_div {
            width: 35%;
            height: 3rem;
            position: relative;
            display: flex;
          }

          .knowmore_button {
            height: 100%;
            width: 100%;
            border: none;
            background-color: #0055a5;
            font-size: 1rem;
            border-radius: 1.5rem;
            font-weight: 600;
            color: white;
          }

          .knowmore_arrow {
            position: absolute;
            height: 100%;
            width: 2.7rem;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            border-radius: 50%;
            right: 0;
            top: 0;
            cursor: pointer;
            box-shadow: 0 10px 10px rgb(0 0 0 / 0.2);
          }
        }
      }

      .carousel_social_links {
        position: absolute;
        right: 2%;
        height: 20%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        z-index: 9;

        .social_icons {
          cursor: pointer;

          svg {
            color: white;
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}

//  Default Css

.carousel-status {
  display: none;
}

.main_footer_div {
  // height: 50vh;
  box-shadow: 0px -29px 29px #00000029;
}
@media screen and (orientation: landscape) {
  .top_carousel_main_div {
    // margin-top: 10vh;
    // height: 90vh;
    height: 100vh !important;
    width: 100%;

    .landing_page_carousel_comp_div {
      height: 100%;
      width: 100%;
      position: relative;

      .carousel_component {
        height: 100vh;
        width: 100%;

        .image_carousel_main_div {
          height: 100% !important;
          width: 100% !important;

          &.tab {
            display: none !important;
          }
          &.mobile {
            display: none !important;
          }
          &.desktop {
            display: block !important;
          }

          .carousel-slider {
            height: 100% !important;
            width: 100% !important;

            .carousel_image {
              height: 100%;
              width: 100%;
              object-fit: fill;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 900px) and (orientation: landscape) {
  .top_carousel_main_div
    .landing_page_carousel_comp_div
    .carousel_social_links
    .social_icons
    svg {
    color: white;
    font-size: 1.2rem;
    height: 100%;
    width: 64%;
  }
}
